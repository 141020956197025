import AppRequest from './AppRequest'
const urlVerifyEmailCode = '/api_v1/email/verify/code'
const urlSendEmailCode = '/api_v1/email/send/verify/code'
const urlCheckIfEmailValid = '/api_v1/email/check/valid'
class EmailRequest extends AppRequest {

    verifyEmailCode(email,type,code){
        return this.post(urlVerifyEmailCode,{
            email,
            type,
            code
        })
    
    }
    sendEmailCode(email,type){
        return this.post(urlSendEmailCode,{
            email,
            codeType:type
        })
    }

    
    checkIfEmailValid(email,type){
        return this.get(urlCheckIfEmailValid,{email,type})
    }

    sendRegisterCode(email){
        return this.sendEmailCode(email,'register')
    }

    sendLoginCode(email){
        return this.sendEmailCode(email,'login')
    }

    sendResetCode(email){
        return this.sendEmailCode(email,'reset')
    }
  
}

const  emailReq = new EmailRequest();
export default emailReq;